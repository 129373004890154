import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";

function HeroSection() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      <div className="pic-wrapper">
        <figure
          className="pic-1"
          style={{ background: `url("${rpdata?.stock?.[6]}")` }}
        ></figure>
        <figure
          className="pic-2"
          style={{ background: `url("${rpdata?.stock?.[10]}")` }}
        ></figure>
        <figure
          className="pic-3"
          style={{ background: `url("${rpdata?.stock?.[12]}")` }}
        ></figure>
        <figure
          className="pic-4"
          style={{ background: `url("${rpdata?.stock?.[2]}")` }}
        ></figure>
        <div className="z-10 relative w-4/5 mx-auto pt-[100px] pb-[100px] md:pb-[100px] md:pt-[100px] grid md:grid-cols-2 grid-cols-1 ">
          <div className="w-full flex flex-col justify-center items-center">
            <h1 className="text-white md:text-start text-center">
              {rpdata?.dbSlogan?.[1].slogan}
            </h1>
            <p className="text-white md:text-start text-center">{rpdata?.dbValues?.[1].description}</p>
            <div className="flex md:justify-start justify-center md:block w-full">
              <ButtonContent/>
            </div>
          </div>
          <div className="w-auto h-auto flex justify-center items-start pt-10">
            <img
              src={"https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Años%20de%20experiencia%2FRecurso%203.png?alt=media&token=9ac96294-7663-4ce4-96cb-7e79f370621d"}
              alt='no found'
              loading='lazy'
              className='w-[200px] h-[200px] md:w-[350px] md:h-[350px]'
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default HeroSection;
